<template>
  <div class="story">
    <div class="txt">
      <p>{{langTxt[lang].story[0]}}</p>

      <p>{{langTxt[lang].story[1]}}</p>

      <p>{{langTxt[lang].story[2]}}</p>

      <p>{{langTxt[lang].story[3]}}</p>

      <p>{{langTxt[lang].story[4]}}</p>
    </div>
  </div>
</template>
<script>
  import {
    defineComponent,
    onMounted,
    computed
  } from "vue";
  import {
    useStore
  } from "vuex";

  export default defineComponent({
    name: "story",
    components: {},
    setup() {
      let store = useStore();
      // let iframeSerc = ref("");
      onMounted(() => {});
      let lang = computed(() => {
        return store.state.lang;
      });
      let langTxt = store.state.text;

      return {
        langTxt,
        lang,
      };
    },
  });
</script>
<style scoped lang="scss">
  .txt {
    width: 10rem;
    margin: 1rem auto;
    color: #fff;
    font-size: 0.18rem;

    p {
      margin: 0.5rem 0;
      line-height: 0.3rem;
    }
  }
  @media screen and (max-width: 1059px){
    .story{
      width: 7rem;
      margin-left: auto;
      margin-right: auto;
      .txt{
        width: 100%;
      }
    }
  }
</style>